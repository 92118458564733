import {
  CourseConfig,
  getColorForSmirk,
  getDescriptionForSmirk,
  useCourseConfigContext,
} from "../contexts/CourseConfigContext";
import { EvalDoc, useEvalContext } from "../contexts/EvalContext";
import React, { useEffect } from "react";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../utils/firebase";

import { Smirks } from "./Smirks";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../contexts/ProjectContext";

type ReviewedSmirksProps = {
  date: Date;
};

const ReviewedSmirks: React.FC<ReviewedSmirksProps> = ({ date }) => {
  const [submissions, setSubmissions] = React.useState<EvalDoc[]>([]);
  const evalCx = useEvalContext();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const projectContext = useProjectContext();
  const courseConfigCtx = useCourseConfigContext();
  const [editStartDate, setEditStartDate] = React.useState<Date | null>(null);

  useEffect(() => {
    if (!projectContext) return;
    const fetchStartDate = async () => {
      const docRef = doc(db, `/prod/${projectContext.lastSelectedProject}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data()?.editStartDate) {
        setEditStartDate(docSnap.data()?.editStartDate.toDate());
      }
    };
    fetchStartDate();
  });

  useEffect(() => {
    if (!projectContext) return;
    const fetchSubmissions = async () => {
      const beginningOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const beginningOfNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );

      const q = query(
        collection(
          db,
          `/prod/${projectContext.lastSelectedProject}/overview/${user?.email}/survey`
        ),
        where("time", ">=", Timestamp.fromDate(beginningOfMonth)),
        where("time", "<", Timestamp.fromDate(beginningOfNextMonth))
      );
      return onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const docs = querySnapshot.docs.map((doc) => {
            const result = doc.data() as EvalDoc;
            result.id = doc.id;
            return result;
          });
          setSubmissions(docs);
        }
      });
    };
    fetchSubmissions();
  }, [date, user?.email, projectContext]);

  // number of days in the month of date
  const num_days = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const days = Array.from(Array(num_days).keys());

  const getSmirks = (day: number) => {
    const submissionsForDay = submissions.filter(
      (submission) =>
        submission.time.toDate().getDate() === day &&
        submission.time.toDate().getMonth() === date.getMonth()
    );
    if (submissionsForDay.length === 0) return [];
    const evalDoc = submissionsForDay[0];
    const smirks = evalDoc.response.map((response) => response.rating);
    return smirks;
  };

  const isBeforeToday = (day: number) => {
    const today = new Date();
    const theDay = new Date(date.getFullYear(), date.getMonth(), day);
    return theDay < today;
  };

  const smirkRow = (smirkConfigCtx: CourseConfig | null, day: number) => {
    const smirks = getSmirks(day);
    if (smirks.length === 0)
      return (
        <div className="w-full text-center text-gray-300 italic col-span-8">
          {isBeforeToday(day) && withinPastFiveDays(day)
            ? "Nothing submitted."
            : ""}
        </div>
      );
    return smirks.map((s, index) => (
      <div className="flex justify-center border-r-2" key={index}>
        <img
          src={Smirks[s].raw_file}
          style={{ backgroundColor: getColorForSmirk(smirkConfigCtx, s) }}
          className="h-8 rounded-xl"
          alt={getDescriptionForSmirk(smirkConfigCtx, s)}
        />
      </div>
    ));
  };

  const onEdit = (day: number) => {
    const editDay = new Date(date.getFullYear(), date.getMonth(), day);
    const submissionForDay = submissions.find(
      (submission) => submission.time.toDate().getDate() === day
    );
    if (submissionForDay) {
      // exists
      evalCx.setTargetDate(editDay);
      evalCx.setEvalDoc(submissionForDay);
      evalCx.setLoading(false);
      navigate(`/evaluation?sid=${submissionForDay.id}`);
    } else {
      // doesn't exist
      evalCx.setTargetDate(editDay);
      evalCx.setEvalDoc(null);
      evalCx.setLoading(false);
      navigate("/evaluation");
    }
  };

  const withinPastFiveDays = (day: number) => {
    const today = new Date();
    const dayDate = new Date(date.getFullYear(), date.getMonth(), day);
    let lowerBound = new Date();
    if (!editStartDate) {
      lowerBound = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 4
      );
    } else {
      lowerBound = editStartDate;
    }
    const tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    return dayDate >= lowerBound && dayDate < tomorrow;
  };
  return (
    <div className="h-full w-full flex flex-col">
      <div className="grow h-0 overflow-y-scroll">
        {days.map((value, index) => (
          <div key={index} className="flex flex-row border-b-2 items-center">
            <div className="p-2 w-[3rem]">{value + 1}</div>
            <div className="flex-1 grid grid-cols-4 md:grid-cols-6 p-2 gap-2">
              {smirkRow(courseConfigCtx, value + 1)}
            </div>
            <div className="p-2 w-[5rem]">
              <button
                className={`px-2 py-1 rounded bg-blue-400 text-white ${
                  withinPastFiveDays(value + 1) ? "" : "hidden"
                }`}
                onClick={() => onEdit(value + 1)}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewedSmirks;
