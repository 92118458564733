import React, { ReactNode, useState } from "react";
import { auth, db } from "../utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";
import { useProjectContext } from "./ProjectContext";

type SmirkStatus = {
    enabled: boolean;
    required: boolean;
    description: string;
    color: string;
}

export type CourseConfig = {
  default: number;
  showReview: boolean | undefined;
  f0: SmirkStatus;
  f1: SmirkStatus;
  f2: SmirkStatus;
  f3: SmirkStatus;
  f4: SmirkStatus;
};

const getColorForSmirk = (config: CourseConfig | null, index: number) => {
  if (!config) return "";
  switch (index) {
    case 0:
      return config.f0.color;
    case 1:
      return config.f1.color;
    case 2:
      return config.f2.color;
    case 3:
      return config.f3.color;
    case 4:
      return config.f4.color;
  }
  return "";
};

const getDescriptionForSmirk = (config: CourseConfig | null, index: number) => {
  if (!config) {
    return "";
  }
  switch (index) {
    case 0:
      return config.f0.description;
    case 1:
      return config.f1.description;
    case 2:
      return config.f2.description;
    case 3:
      return config.f3.description;
    case 4:
      return config.f4.description;
  }
  return "";
};

const isSmirkEnabled = (config: CourseConfig | null, index: number) => {
  if (!config) {
    return false;
  }
  switch (index) {
    case 0:
      return config.f0.enabled;
    case 1:
      return config.f1.enabled;
    case 2:
      return config.f2.enabled;
    case 3:
      return config.f3.enabled;
    case 4:
      return config.f4.enabled;
  }
  return false;
};

const isCommentRequired = (config: CourseConfig | null, index: number) => {
  if (!config) {
    return false;
  }
  switch (index) {
    case 0:
      return config.f0.required;
    case 1:
      return config.f1.required;
    case 2:
      return config.f2.required;
    case 3:
      return config.f3.required;
    case 4:
      return config.f4.required;
  }
  return false;
};

const defaultCourseConfig = {
  default: 2,
  showReview: true,
  f0: {
    enabled: true,
    required: true,
    description: "Did something extraordinary",
    color: "#a8d453",
  },
  f1: {
    enabled: true,
    required: false,
    description: "Went above and beyond",
    color: "#c4db6b",
  },
  f2: {
    enabled: true,
    required: false,
    description: "Contributed as expected",
    color: "#f6d567",
  },
  f3: {
    enabled: true,
    required: false,
    description: "Did less than needed",
    color: "#df8270",
  },
  f4: {
    enabled: true,
    required: true,
    description: "Was not helpful at all",
    color: "#eb6145",
  },
};


const CourseConfigContext = React.createContext<CourseConfig | null>(null);

const CourseConfigContextProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [user] = useAuthState(auth);
    const [courseConfig, setCourseConfig] =
        React.useState<CourseConfig | null>(null);
    const projectContext = useProjectContext();
    const lastSelectedProject = projectContext?.lastSelectedProject;

    React.useEffect(() => {
        if (lastSelectedProject) {
            const docRef = doc(
                db,
                `/prod/${lastSelectedProject}`
            );
            const unsub = onSnapshot(docRef, (doc) => {
                const onlineSmirkConfig = doc.data()?.smirkConfig;
                if (onlineSmirkConfig) {
                    setCourseConfig(onlineSmirkConfig);
                } else {
                    setCourseConfig(defaultCourseConfig);
                }
            });
            return unsub;
        }
    }, [user, lastSelectedProject]);


    return (
        <CourseConfigContext.Provider value={courseConfig}>
            {children}
        </CourseConfigContext.Provider>
    );
};

const useCourseConfigContext = () => {
    const context = React.useContext(CourseConfigContext);
    if (context === undefined) {
        console.log(context);
        throw new Error("useCourseConfigContext must be used within a ProjectProvider");
    }
    return context;
};

export {
    CourseConfigContextProvider,
    useCourseConfigContext, 
    isSmirkEnabled, 
    isCommentRequired, 
    getDescriptionForSmirk, 
    getColorForSmirk
};
