import {
  CourseConfig,
  isCommentRequired,
} from "../../contexts/CourseConfigContext";
import { collection, doc, writeBatch } from "firebase/firestore";

import { MemberDoc } from "../../contexts/OverviewContext";
import { db } from "../../utils/firebase";

export const ResponseOptions = {
  dissatisfied: {
    participation: [
      "Did not attend planned group activity/activities",
      "Was there, but did not engage in group discussion(s)",
      "Was there, but did not engage in online discussion(s)",
      "Did not volunteer for follow-on work",
      "Was consistently late/very late",
    ],
    collaboration: [
      "Was not open to hearing the ideas of others",
      "Was disrespectful of others in the conversation",
      "Was dismissive of the work/contributions of others",
      "Overwrote the work of others altogether",
      "Did not foster a cooperative atmosphere",
      "Did not offer to help when so needed",
      "Did not provide constructive feedback",
    ],
    contributions: [
      "Missed the deadline for their contributions",
      "Delivered poor quality of work/no work at all",
      "Others had to step in and do the work for them",
      "Did merely cosmetic work/no deep thinking",
      "Only put their name on the final deliverable",
    ],
  },
  satisfied: {
    participation: [
      "Despite being unable to attend in person, participated fully from remote",
      "After missing a meeting, proactively caught up with the project",
      "Volunteered for a major piece of follow-on work",
      "Took significant initiative",
    ],
    collaboration: [
      "Succeeded in bringing everyone into the conversation",
      "Found ways to integrate ideas from others in ways not immediately apparent",
      "Stood up and corrected a team member who was being disrespectful of others",
      "Broke through a tense/difficult situation, bringing harmony to the discussion(s)",
      "Provided outstanding constructive feedback in a positive manner",
      "Went above and beyond in helping a team member",
    ],
    contributions: [
      "Produced work of amazing quality",
      "Delivered key work significantly earlier than needed",
      "Stepped in and did the work that someone else should have done",
      "Discovered and solved a major problem with our approach",
    ],
  },
};

export type SmirkResponseType = {
  name: string;
  email: string;
  rating: number;
  participation: string[];
  collaboration: string[];
  contributions: string[];
  comment: string;
  private_comment: string;
};

export function getDefaultResponseObject(
  member: MemberDoc,
  defaultRating: number
): SmirkResponseType {
  return {
    name: member.name,
    email: member.email,
    rating: defaultRating,
    participation: [],
    collaboration: [],
    contributions: [],
    comment: "",
    private_comment: "",
  };
}

export const getDefaultResponseObjectArray = (
  members: MemberDoc[],
  defaultRating: number
) => {
  return members.map((value, index) =>
    getDefaultResponseObject(value, defaultRating)
  );
};

export const requireMoreInformation = (
  courseConfig: CourseConfig,
  response: SmirkResponseType
) => {
  return isCommentRequired(courseConfig, response.rating);
  // return response.rating === 0 || response.rating === 4;
};

export const hasAtLeastOneOption = (response: SmirkResponseType) => {
  if (response.participation.length > 0) return true;
  if (response.collaboration.length > 0) return true;
  if (response.contributions.length > 0) return true;
  if (response.comment.trim().length > 0) return true;
  if (response.private_comment.trim().length > 0) return true;
};

export const checkResponseRequireInformation = (
  courseConfig: CourseConfig,
  responseArray: SmirkResponseType[]
) => {
  for (let i = 0; i < responseArray.length; ++i) {
    if (requireMoreInformation(courseConfig, responseArray[i])) {
      return true;
    }
  }
  return false;
};

export const checkResponseSubmittable = (
  courseConfig: CourseConfig,
  responseArray: SmirkResponseType[]
) => {
  for (let i = 0; i < responseArray.length; ++i) {
    const response = responseArray[i];
    if (
      requireMoreInformation(courseConfig, response) &&
      !hasAtLeastOneOption(response)
    ) {
      return `Missing information for ${response.name}`;
    }
  }
  return "";
};

export const submitResponse = (
  rid: string,
  email: string,
  designStudioId: string,
  team_id: string,
  responseArray: SmirkResponseType[],
  forDate: Date
) => {
  const today = new Date();
  const batch = writeBatch(db);

  const surveyInfoPath = `prod/${designStudioId}/teams/${team_id}/surveyInfo`;
  const surveyPath = `prod/${designStudioId}/overview/${email}/survey`;

  const editMode = rid !== "";
  // Save survey response
  const newSurveyInfoRef = !editMode
    ? doc(collection(db, surveyInfoPath))
    : doc(collection(db, surveyInfoPath), rid);

  const surveyRef = doc(collection(db, surveyPath), newSurveyInfoRef.id);

  if (!editMode) {
    batch.set(newSurveyInfoRef, {
      email,
      time: forDate,
      lastEdit: today,
    });
    batch.set(surveyRef, {
      email,
      time: forDate,
      lastEdit: today,
      response: responseArray,
    });
  } else {
    batch.update(newSurveyInfoRef, {
      lastEdit: today,
    });
    batch.update(surveyRef, {
      response: responseArray,
      lastEdit: today,
    });
  }

  // for each member
  responseArray.forEach((v, i) => {
    const name = v.name;
    const remail = v.email;
    const rating = v.rating;
    const comment = v.comment;
    const private_comment = v.private_comment;
    const contributions = v.contributions;
    const collaboration = v.collaboration;
    const participation = v.participation;

    // increment calendar count
    const newCalDocRef = doc(
      collection(db, `/prod/${designStudioId}/overview/${remail}/calendar`),
      surveyRef.id
    );
    if (!editMode) {
      batch.set(newCalDocRef, {
        name,
        email,
        rating,
        comment,
        private_comment,
        contributions,
        collaboration,
        participation,
        time: forDate,
        lastEdit: today,
      });
    } else {
      batch.update(newCalDocRef, {
        name,
        rating,
        comment,
        private_comment,
        contributions,
        collaboration,
        participation,
        lastEdit: today,
      });
    }
  });
  return batch.commit();
};
