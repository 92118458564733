import {
  CourseConfig,
  getColorForSmirk,
  getDescriptionForSmirk,
  isSmirkEnabled,
  useCourseConfigContext,
} from "../contexts/CourseConfigContext";
import React, { useEffect } from "react";
import {
  Timestamp,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../utils/firebase";

import BarChart from "./BarChart";
import { BiArrowBack } from "react-icons/bi";
import { CalendarDoc } from "../contexts/CalendarContext";
import { Smirks } from "./Smirks";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";
import { useProjectContext } from "../contexts/ProjectContext";

type ReceivedSmirksProps = {
  date: Date;
  setShouldDisplay: (shouldDisplay: boolean) => void;
};
const ReceivedSmirks: React.FC<ReceivedSmirksProps> = ({
  date,
  setShouldDisplay,
}) => {
  const [calendarDocs, setCalendarDocs] = React.useState<CalendarDoc[]>([]);
  const [user] = useAuthState(auth);

  const [focusDocs, setFocusDocs] = React.useState<CalendarDoc[]>([]);
  const [focusDate, setFocusDate] = React.useState<Date>(date);
  const projectContext = useProjectContext();
  const courseConfigCtx = useCourseConfigContext();

  useEffect(() => {
    if (!projectContext) return;
    const fetchCalendarDocs = async () => {
      const beginningOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const beginningOfNextMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );
      const q = query(
        collection(
          db,
          `/prod/${projectContext.lastSelectedProject}/overview/${user?.email}/calendar`
        ),
        where("time", ">=", Timestamp.fromDate(beginningOfMonth)),
        where("time", "<", Timestamp.fromDate(beginningOfNextMonth))
      );

      return onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const docs = querySnapshot.docs.map(
            (doc) => doc.data() as CalendarDoc
          );
          setCalendarDocs(docs);
        }
      });
    };
    fetchCalendarDocs();
  }, [date, user?.email, projectContext]);

  const num_days = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const days = Array.from(Array(num_days).keys());

  const getSmirks = (day: number) => {
    const calendarDocsForDay = calendarDocs.filter(
      (calendarDoc) =>
        calendarDoc.time.toDate().getDate() === day &&
        calendarDoc.time.toDate().getMonth() === date.getMonth()
    );
    return calendarDocsForDay.map((v) => v.rating);
  };

  const isBeforeToday = (day: number) => {
    const today = new Date();
    const theDay = new Date(date.getFullYear(), date.getMonth(), day);
    return theDay < today;
  };

  const smirkRow = (courseConfig: CourseConfig | null, day: number) => {
    const smirks = getSmirks(day);
    if (smirks.length === 0)
      return (
        <div className="w-full text-center text-gray-300 italic col-span-8">
          {isBeforeToday(day) ? "No feedback for the day." : ""}
        </div>
      );
    return smirks.map((s, index) => (
      <div className="flex justify-center border-r-2" key={index}>
        <img
          src={Smirks[s].raw_file}
          style={{ backgroundColor: getColorForSmirk(courseConfig, s) }}
          className="h-8 rounded-xl"
          alt={getDescriptionForSmirk(courseConfig, s)}
        />
      </div>
    ));
  };

  const updateFocusDocs = (docs: CalendarDoc[]) => {
    if (docs.length === 0) {
      setFocusDocs([]);
      setShouldDisplay(true);
    } else {
      setFocusDocs(docs);
      setShouldDisplay(false);
    }
  };

  const row = (day: number) => {
    const smirks = getSmirks(day);
    const calendarDocsForDay = calendarDocs.filter(
      (calendarDoc) => calendarDoc.time.toDate().getDate() === day
    );

    const onViewClicked = () => {
      updateFocusDocs(calendarDocsForDay);
      const fd = date;
      date.setDate(day);
      setFocusDate(fd);
    };

    return (
      <>
        <div className="p-2 w-[3rem]">{day}</div>
        <div className="flex-1 grid grid-cols-4 md:grid-cols-6 p-2 gap-2">
          {smirkRow(courseConfigCtx, day)}
        </div>
        {smirks.length !== 0 && (
          <div className="p-2 w-[5rem]">
            <button
              className="px-2 py-1 rounded bg-blue-400 text-white"
              onClick={onViewClicked}
            >
              View
            </button>
          </div>
        )}
      </>
    );
  };

  const getSmilies = (docs: CalendarDoc[]) => {
    const result = [0, 0, 0, 0, 0];
    docs.forEach((doc) => {
      result[doc.rating] += 1;
    });
    return result;
  };

  const hasDisplayableReview = (docs: CalendarDoc[]) => {
    for (const doc of docs) {
      if (doc.comment) return true;
      if (doc.collaboration && doc.collaboration.length !== 0) return true;
      if (doc.contributions && doc.contributions.length !== 0) return true;
      if (doc.participation && doc.participation.length !== 0) return true;
    }
    return false;
  };

  return (
    <div className="h-full w-full flex flex-col">
      {focusDocs.length === 0 && (
        <div className="grow h-0 overflow-y-scroll">
          {days.map((value, index) => (
            <div key={index} className="flex flex-row border-b-2 items-center">
              {row(value + 1)}
            </div>
          ))}
        </div>
      )}

      {focusDocs.length !== 0 && (
        <div className="grow h-0 flex flex-col">
          {/* back button */}
          <div>
            <button
              className="text-black text-xl py-4 pr-4 rounded flex flex-row items-center space-x-2"
              onClick={() => updateFocusDocs([])}
            >
              <BiArrowBack /> <p>Back</p>
            </button>
            <div>
              <p>
                Smirks received on{" "}
                <span className="underline">
                  {moment(focusDate).format("MMMM Do, YYYY")}
                </span>
              </p>
            </div>
          </div>
          {/* contents */}
          <div className="overflow-y-scroll">
            {/* faces */}
            <div className="flex flex-row justify-around pt-8 pb-4 px-4">
              {Smirks.map((value, index) => {
                if (!isSmirkEnabled(courseConfigCtx, index)) {
                  return;
                }
                return (
                  <img
                    className="w-12 rounded-xl"
                    key={index}
                    src={value.raw_file}
                    style={{
                      backgroundColor: getColorForSmirk(courseConfigCtx, index),
                    }}
                    alt={getDescriptionForSmirk(courseConfigCtx, index)}
                  />
                );
              })}
            </div>
            {/* bar chart */}
            <div className="h-40">
              <BarChart smilies={getSmilies(focusDocs)} />
            </div>
            {/* comments */}
            {hasDisplayableReview(focusDocs) && (
              <div className="py-2 my-2 border-t-2 space-y-4 flex flex-col">
                <div className="">
                  <p className="font-bold text-2xl">Feedback given to you</p>
                </div>
                <div className="flex-1 overflow-y-scroll">
                  {focusDocs
                    .filter(
                      (v) =>
                        v.comment ||
                        (v.collaboration && v.collaboration.length > 0) ||
                        (v.contributions && v.contributions.length > 0) ||
                        (v.participation && v.participation.length > 0)
                    )
                    .map((v, i) => (
                      <div className="flex flex-row w-full pb-4" key={i}>
                        <div className="w-16">
                          <img
                            src={Smirks[v.rating].raw_file}
                            style={{
                              backgroundColor: getColorForSmirk(
                                courseConfigCtx,
                                v.rating
                              ),
                            }}
                            className="w-12 rounded-xl"
                            alt={getDescriptionForSmirk(
                              courseConfigCtx,
                              v.rating
                            )}
                          />
                        </div>
                        <div className="flex-1 overflow-hidden">
                          {v.comment && <p>{v.comment}</p>}

                          <ul className="list-disc list-inside">
                            {v.collaboration &&
                              v.collaboration.map((c, i) => (
                                <li key={i}>{c}</li>
                              ))}
                            {v.contributions &&
                              v.contributions.map((c, i) => (
                                <li key={i}>{c}</li>
                              ))}
                            {v.participation &&
                              v.participation.map((c, i) => (
                                <li key={i}>{c}</li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceivedSmirks;
